<template>
  <v-autocomplete
    v-model="searchBox"
    :items="items"
    :search-input.sync="search"
    cache-items
    flat
    solo
    hide-details
    prepend-inner-icon="mdi-magnify"
    append-icon=""
    :label="$t('components.toolbarSearch.label')"
    hide-no-data
    auto-select-first
    clearable
    item-text="firstname"
    item-value="id"
    :loading="loading"
    :filter="customFilter"
  >
    <template #selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        @click="data.select"
        @click:close="clearSearchBox"
      >
        <v-avatar left>
          <v-img
            :src="
              data.item.image
                ? `${constants.CDN_STORAGE}/user/${data.item.image}`
                : helpers.getPlaceholderImage()
            "
          />
        </v-avatar>
        {{ data.item.email }}
      </v-chip>
    </template>
    <template #item="data">
      <v-list-item
        :href="`${constants.ACCOUNT_URL}/admin/users/${data.item.id}`"
        target="_blank"
      >
        <v-list-item-avatar>
          <v-avatar size="35px">
            <v-img
              :src="
                data.item.image
                  ? `${constants.CDN_STORAGE}/user/${data.item.image}`
                  : helpers.getPlaceholderImage()
              "
              alt="avatar"
            />
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title
            >{{ data.item.firstname }}
            {{ data.item.lastname }}</v-list-item-title
          >
          <v-list-item-subtitle>{{ data.item.email }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>
<script>
import constants from '@/utils/constants';
import helpers from '@/utils/helpers';
import AuthProxy from '@/proxies/AuthProxy';

export default {
  data() {
    return {
      constants,
      helpers,
      loading: false,
      searchBox: null,
      search: null,
      items: [],
    };
  },
  watch: {
    search(val) {
      if (val && val !== this.searchBox) {
        this.getDataFromApiDebounced(val);
      }
    },
  },
  methods: {
    /**
     * Get data from api debounced with a delay of 500 ms
     *
     * @param {string} text - optional text from input form
     *
     * @returns {void}
     */
    getDataFromApiDebounced: _.debounce(function search(text) {
      this.getDataFromApi(text);
    }, 500),
    /**
     * Get data from api
     *
     * @returns {void}
     */
    async getDataFromApi() {
      if (this.search) {
        // set loading data
        this.loading = true;

        // prepare params data
        const params = {
          search: this.search,
        };

        try {
          const response = await new AuthProxy().search(params);
          this.items = response.data;
        } catch (err) {
          this.$bus.fire('show-snackbar', { err, options: { timeout: 6000 } });
          console.warn(err);
        }
        this.loading = false;
      }
    },

    /**
     * Custom filter, which is querying for either firstname, lastname, email or id match
     *
     * @param {object} item - item to inspect
     * @param {string} queryText - current query text
     *
     * @returns {boolean} match - indicator whether its a match or not
     */
    customFilter(item, queryText) {
      const searchText = queryText.toLowerCase().split(' ');
      return searchText.some(
        (searchPart) =>
          (item.firstname &&
            item.firstname.toLowerCase().includes(searchPart)) ||
          (item.lastname && item.lastname.toLowerCase().includes(searchPart)) ||
          (item.email && item.email.toLowerCase().includes(searchPart)) ||
          (item.id && `${item.id}`.includes(searchPart))
      );
    },

    /**
     * Clear the search box
     *
     * @returns {void}
     */
    clearSearchBox() {
      this.searchBox = null;
    },
  },
};
</script>
