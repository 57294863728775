/* ============
 * Mutations for the ad module
 * ============
 *
 * The mutations that are available on the
 * ad module.
 */

import { updateField } from 'vuex-map-fields';
import { i18n } from '@/plugins/vue-i18n';
import * as types from './mutation-types';
import { getInitialState } from './state';
import AdSettings from './adSettings';

export default {
  // Add the `updateField` mutation to the
  // `mutations` of the Vuex store instance.
  updateField,

  [types.SET_ADS_ANALYTICS](state, data) {
    state.ads = data;
  },

  [types.RESET_STATE](state) {
    // reset state to initial state
    Object.assign(state, getInitialState());
  },
  [types.SAVE](state, ad) {
    state.ads.push(ad);
  },

  [types.UPDATE](state, ad) {
    const adIndex = state.ads.findIndex((obj) => obj.id === ad.id);
    state.ads[adIndex] = ad;
  },

  [types.DELETE](state, id) {
    const adIndex = state.ads.findIndex((obj) => obj.id === id);
    state.ads.splice(adIndex, 1);
  },

  [types.SET_ADS](state, ads) {
    state.ads = ads.data;
    state.total_ads = ads.total;
  },

  [types.SET_AD](state, ad) {
    state.ad = {
      ...ad.detail,
      domain_id: ad.detail.domain_id || '0',
      device_type: ad.detail.device_type || 'all',
      name: ad.name,
      isStatic: !!ad.detail.is_static,
      nativeTag: ad.detail.native_tag,
      settings: new AdSettings(ad.detail.settings),
      rotate: !!ad.detail.rotate,
      duration: ad.detail.duration == null ? 4 : ad.detail.duration,
    };
  },
  [types.UPDATE_AD_PARAMS](state, adParams) {
    state.ad = {
      ...state.ad,
      ...adParams,
    };
  },

  // [types.SET_ORGANISATIONS](state, organisations) {
  //   console.log(organisations);
  //   state.ad.organisations = organisations;
  // },

  [types.SET_ELEMENTS_QUESTIONS_ANSWERS](state, elements) {
    // TODO: add check for disabled answers/questions
    state.elements = _.map(elements, (element) =>
      _.extend({}, element, { disabled: false })
    );

    let allAnswers = [];
    let allQuestions = [];

    for (let i = 0; i < state.elements.length; i += 1) {
      let curElementQuestions = _.map(state.elements[i].questions, (question) =>
        _.extend({}, question, { disabled: false })
      );
      allQuestions = allQuestions.concat(curElementQuestions);

      for (let i = 0; i < curElementQuestions.length; i += 1) {
        if (i !== 0) {
          allAnswers.push({ divider: 'true' });
        }
        allAnswers.push({
          header: curElementQuestions[i].question,
        });
        for (let j = 0; j < curElementQuestions[i].answers.length; j += 1) {
          allAnswers.push({
            id: curElementQuestions[i].answers[j].id,
            answer:
              curElementQuestions[i].answers[j].answer ||
              i18n.t('common.answerMedia.fallback', { id: j + 1 }),
            question: curElementQuestions[i].question,
            'question.created_at': curElementQuestions[i].created_at,
            questionId: curElementQuestions[i].id,
            disabled: false,
          });
        }
      }
    }
    state.questions = allQuestions;
    state.answers = allAnswers;
  },

  [types.SET_PIXELS](state, pixels) {
    state.pixels = pixels;
  },

  [types.RESET_QUESTIONS_AND_ANSWERS](state) {
    state.questions = state.questions.map((question) => {
      question.disabled = false;
      return question;
    });
    state.answers = state.answers.map((answer) => {
      answer.disabled = false;
      return answer;
    });
  },

  [types.DISABLE_ANSWERS](state, diff) {
    for (let i = 0; i < diff.length; i += 1) {
      state.answers = state.answers.map((answer) => {
        if (answer.questionId === diff[i]) {
          answer.disabled = !answer.disabled;
        }
        return answer;
      });
    }
  },

  [types.DISABLE_QUESTIONS](state, params) {
    const question = _.find(state.questions, ['id', params.answer.question_id]);
    if (params.newValues.length === 0 || params.allowDisabling) {
      question.disabled = !question.disabled;
    }
  },

  [types.RESET_FIELDS](state) {
    state.ad = {
      isStatic: true,
      name: '',
      headline: '',
      cta_label: '',
      delay: 2,
      duration: 4,
      rotate: false,
      approved: false,
      tag: '',
      nativeTag: '',
      settings: new AdSettings({}),
      native: false,
      answers: [],
      questions: [],
      covers: [],
      results: [],
      adExperiences: [],
      image_data: '',
      display: 'inline',
      display_timing: 'after_vote',
      domain_id: '0',
      device_type: 'all',
    };
    (state.adAllExperienceActive = false), (state.validationErrors = {});
  },

  [types.SET_TARGET_URL](state, value) {
    state.ad.target_url = value;
  },

  [types.SET_AD_QUESTION_MAPPING](state, value) {
    state.ad.questions = value;
  },

  [types.SET_AD_ANSWER_MAPPING](state, value) {
    state.ad.answers = value;
  },

  [types.SET_AD_COVER_MAPPING](state, value) {
    state.ad.covers = value;
  },

  [types.SET_AD_RESULT_MAPPING](state, value) {
    state.ad.results = value;
  },

  [types.SET_AD_EXPERIENCES_ITEM_MAPPING](state, value) {
    state.ad.adExperiences = value;
  },

  [types.SET_AD_EXPERIENCES_ACTIVE](state, value) {
    state.adAllExperienceActive = value;
  },

  [types.DISABLE_QUESTIONS_AND_ANSWERS](state) {
    // disable questions on load
    for (let i = 0; i < state.ad.answers.length; i += 1) {
      const answer = _.find(state.answers, ['id', state.ad.answers[i]]);
      const question = _.find(state.questions, [
        'id',
        answer ? answer.questionId : 0,
      ]);
      if (question) {
        question.disabled = true;
      }
    }

    // disable answers on load
    for (let i = 0; i < state.ad.questions.length; i += 1) {
      const answers = _.filter(state.answers, [
        'questionId',
        state.ad.questions[i],
      ]);
      if (answers) {
        for (let j = 0; j < answers.length; j += 1) {
          answers[j].disabled = true;
        }
      }
    }
  },

  [types.SET_AD_TARGETINGS](state, value) {
    state.adTargetings = value;
  },
};
