export default class AdSettings {
  constructor(settings) {
    const {
      type,
      floating,
      domain,
      value = 0.0001,
      inline,
      interstitial,
      bg,
      adMinH,
      adMaxH,
      scriptSrc,
      expandButton,
    } =
      (settings &&
        (typeof settings === 'string' ? JSON.parse(settings) : settings)) ||
      {};
    this.type = type;
    this.floating = !!floating;
    this.domain = domain;
    this.value = value || 0.0001;
    this.inline = this.getDefaultSlotSettings(inline);
    this.interstitialOn = !!interstitial && interstitial.elementId;
    this.interstitial = this.getDefaultSlotSettings(interstitial);
    this.scriptSrc = scriptSrc;
    this.expandButton =
      expandButton ||
      `<span class="expand-btn">
    <svg viewBox="0 0 200 200">
        <polyline points="50,125 100,75 150,125" fill="none" stroke="white" stroke-width="12" stroke-linejoin="round" stroke-linecap="round"/>
    </svg>
</span>
<style>
    .pinpoll-ad-expand-btn {
        position: absolute;
        top: 0.5rem;
        outline: none;
    }
    .pinpoll-ad-expand-btn .expand-btn {
        transition: all 0.3s ease;
        background: lightgrey;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.6rem;
        height: 2.6rem;
        position: relative;
        cursor: pointer;
        pointer-events: visible;
    }
    .pinpoll-ad-expand-btn .expand-btn svg {
        width: 80%;
        height: 80%;
        z-index: 2;
        transform-origin: center;
    }
    .pinpoll-ad-expand-btn .expand-btn:hover, .pinpoll-native-ad.expanded .pinpoll-ad-expand-btn .expand-btn {
        background: grey;
    }
    .pinpoll-ad-expand-btn .expand-btn:hover svg, .pinpoll-native-ad.expanded .pinpoll-ad-expand-btn .expand-btn svg {
        transform: rotate(180deg);
    }
</style>`;
    this.bg = bg;
    this.adMinH = adMinH;
    this.adMaxH = adMaxH;
    this.isGoogleAd = +this.type === 1;
  }
  disable() {
    this.isGoogleAd = false;
    this.type = null;
  }
  enable() {
    this.isGoogleAd = true;
    this.type = 1;
  }
  getDefaultSlotSettings(settings) {
    return {
      elementId: 'gpt-passback',
      path: null,
      sizes: '',
      freeSizes: false,
      ...(settings || {}),
      get randomOn() {
        return this.elementId?.includes('%random%');
      },
      get iterateOn() {
        return this.elementId?.includes('%iterate%');
      },
      toggleRandom() {
        const elementId = this.elementId || '';
        this.elementId = this.randomOn
          ? elementId.replace('%random%', '')
          : `${elementId}%random%`;
      },
      toggleIterate() {
        const elementId = this.elementId || '';
        this.elementId = this.iterateOn
          ? elementId.replace('%iterate%', '')
          : `${elementId}%iterate%`;
      },
      toggleFreeSizes() {
        this.freeSizes = !this.freeSizes;
      },
      formatSizes() {
        let input = this.sizes;
        let lastChar = input.charAt(input.length - 1);
        // Only format if the last entered character is a non-digit
        if (/\D/.test(lastChar)) {
          let numbers = input.match(/\d+/g);
          let lastNonDigitPart = input.match(/\D+$/);
          if (numbers) {
            let formattedNumbers = [];
            for (let i = 0; i < numbers.length; i++) {
              if (i % 2 === 0 && i + 1 < numbers.length) {
                formattedNumbers.push(`[${numbers[i]},${numbers[i + 1]}]`);
                i++;
              } else {
                formattedNumbers.push(numbers[i]);
              }
            }
            if (lastNonDigitPart) {
              formattedNumbers.push('');
            }
            this.sizes = formattedNumbers.join(',');
          }
        }
      },
      trimSizes() {
        this.sizes += ',';
        this.formatSizes();
        this.sizes = this.sizes.replace(/,+$/, '');
        if (this.sizes) {
          this.sizes = `[${this.sizes}]`;
        }
      },
    };
  }
  serialize() {
    this.type = this.isGoogleAd ? 1 : null;
    return this.type
      ? JSON.stringify({
          type: this.type,
          scriptSrc: this.scriptSrc,
          bg: this.bg,
          adMinH: this.adMinH,
          adMaxH: this.adMaxH,
          floating: this.floating,
          expandButton: this.floating ? this.expandButton : null,
          domain: this.domain,
          value: this.value,
          inline: this.inline,
          interstitial: this.interstitialOn ? this.interstitial : null,
        })
      : null;
  }
}
